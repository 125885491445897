import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import Layout from 'components/Layout'
import SEO from 'components/SEO'

import { BannerCustom } from 'components/Elements'
import Content from 'components/Content'


const Kleurplaat = () => {
  const { kleurplaatPagina: { acf, yoast_meta: yoast } } = useStaticQuery(graphql`
    {
      kleurplaatPagina:  wordpressPage(wordpress_id: { eq: 986 }) {
        acf {
          banner {
            tekst
          }
           kleurplaat {
            description
             image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
             } 
             link {
               title
               localFile {
                 publicURL
               }
             }
           }
        }
        yoast_meta {
          name
          content
          property
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO yoast={{ meta: yoast }} />
      <div className="kleurplaat">
        <BannerCustom
          to={acf.kleurplaat.link.localFile.publicURL}
          className="color-background-secondary"
          bannerText={acf.banner.tekst}
          bannerButtonText="Download de kleurplaat"
        />
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <Content content={acf.kleurplaat.description} className="mb-4" />
              <a href={acf.kleurplaat.link.localFile.publicURL} download>
                <Img fluid={acf.kleurplaat.image.localFile.childImageSharp.fluid} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Kleurplaat